@use 'styles/base/mixins';
@use 'styles/base/variables';

// new header styles
.pageHeader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: variables.$gb_white;
  z-index: 21;

  @include mixins.screen-md {
    box-shadow: 0 0.1875rem 0.75rem rgba(0, 0, 0, 0.2);
  }
}

:global(.nord) {
  .pageHeader {
    &.isTouch ~ main {
      padding-top: variables.$header-height-mobile;

      @include mixins.screen-md {
        padding-top: 6.125rem; //98px
      }
    }
  }
}

// states
// sticky = fixed at bottom, out of viewport
:global(.pageHeader--sticky) {
  z-index: variables.$z-sticky-header;
}

// reduced = in viewport, narrow mainnav, has to be used with ".pageHeader--sticky"
// other styles affected by this class in main-navigation.scss and meta-navigation.scss
:global(.pageHeader--reduced) {
  transform: translateY(0);
  transition: transform variables.$transition-medium variables.$transition-timing-ease-out;
}

// full = in viewport, full mainnav & visible metanav, has to be used with ".pageHeader--sticky"
// other styles affected by this class in main-navigation.scss and meta-navigation.scss
:global(.pageHeader--full) {
  transform: translateY(0);
}

.toolong {
  strong {
    font-weight: bold;
  }

  a {
    color: variables.$gb_black;
  }

  a > strong {
    font-weight: bold;
    background: variables.$gb_brand-utility-negative;
    color: variables.$gb_white;
  }
}

:global(.pageHeader--meta-nav) ~ main {
  // header height so that content has enough space to top when header is fixed
  @include mixins.screen-md {
    padding-top: 8.625rem; //138px
  }
}

:global(.pageHeader--meta-nav.group.isTouch) ~ main {
  @include mixins.screen-md {
    padding-top: 3.8rem; //60.8px
  }
}

:global(.pageHeader--meta-nav.nord.isTouch) ~ main {
  @include mixins.screen-md {
    padding-top: 6.2rem; // 99.2px
  }
}

:global(main) {
  padding-top: variables.$header-height-mobile;

  :global(.with-mega-menu) & {
    padding-top: 4.25rem;
    transition: padding-top 0.3s ease;

    @media (hover: hover) {
      padding-top: 4rem;
    }

    @include mixins.screen-md {
      padding-top: 0;
      &:global(.search-open) {
        padding-top: 16rem;

        &:global(.mm-sticky) {
          padding-top: 9rem;
        }
      }
    }
  }
}

.gddsMetaNavi {
  height: auto;
  max-height: none;
  transition: none;
  width: 100%;

  @include mixins.screen-md {
    max-height: 2.375rem;
    height: 2.375rem;
    transition: max-height variables.$transition-medium variables.$transition-timing-ease-out;
  }

  // state
  :global(:not(.isTouch).pageHeader--reduced) & {
    @include mixins.screen-md {
      max-height: 0;
      overflow: hidden;
    }
  }

  & > div > div {
    height: auto;
    overflow: visible;
    flex-direction: column-reverse;
    transition: none;
    margin-top: 0;

    // state
    :global(:not(.isTouch).pageHeader--reduced) & {
      @include mixins.screen-md {
        margin-top: -2.375rem;
      }
    }

    @include mixins.screen-md {
      flex-direction: row;
      height: 2.375rem;
      transition: margin-top variables.$transition-medium variables.$transition-timing-ease-out;
    }
  }
}

.nord {
  .desktop {
    display: none;

    .gddsMetaNavi {
      > div {
        z-index: 60;
      }

      button[data-testid='dropdown'] + div {
        min-width: auto;

        ul li {
          padding-right: 1.5rem;
        }
      }
    }
  }

  @include mixins.screen-md {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }
}
